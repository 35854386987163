import type { ComponentProps, FC, MutableRefObject } from 'react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CheckIcon } from '@heroicons/react/24/outline';
import useClassNames from 'helpers/hooks/useClassNames';
import Typography from '../typography';

export interface InputProps extends Omit<ComponentProps<'input'>, 'onChange'> {
    label?: string;
    labelDesc?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    // onPaste?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    variant?: 'primary' | 'secondary';
    labelPosition?: 'top' | 'inline';
    innerRef?: MutableRefObject<HTMLInputElement>;
    error?: string;
    'data-cy'?: string;
    errorMessage?: string;
    isValid?: boolean;
    hideCheckIcon?: boolean;
    validation?: (valueToValidate: string) => boolean;
    renderEndIcon?: () => React.ReactNode;
    customClass?: string;
}

const Input: FC<InputProps> = ({
    label,
    labelDesc,
    onChange,
    onBlur,
    onFocus,
    onPaste,
    variant = 'primary',
    labelPosition = 'top',
    customClass = '',
    className = '',
    innerRef,
    value,
    errorMessage,
    validation,
    children,
    hideCheckIcon,
    ...props
}) => {
    const [isFocused, setIsFocused] = useState(false);
    const [isTouched, setIsTouched] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [isErrored, setIsErrored] = useState(false);
    const [isEdited, setIsEdited] = useState(false);

    useEffect(() => {
        if (validation) {
            const shouldBeValidated = isTouched && isEdited;
            const validated = validation?.(value as string);
            setIsValid(shouldBeValidated && !!validated);
            setIsErrored(shouldBeValidated && !validated);
        }
    }, [isEdited, isTouched, validation, value]);

    const handleChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            onChange?.(event);
            setIsEdited(true);
        },
        [onChange]
    );

    const handleFocus = useCallback(
        (e: React.FocusEvent<HTMLInputElement>) => {
            setIsFocused(true);
            onFocus?.(e);
        },
        [onFocus]
    );

    const handlePaste = useCallback(
        (e: React.ClipboardEvent<HTMLInputElement>) => {
            setIsFocused(true);
            onPaste?.(e);
        },
        [onPaste]
    );
    const handleBlur = useCallback(
        (e: React.FocusEvent<HTMLInputElement>) => {
            setIsFocused(false);
            setIsTouched(true);
            onBlur?.(e);
        },
        [onBlur]
    );

    const bgClassName = useMemo(
        () =>
            ({
                primary: 'bg-input-bg text-input-text',
                secondary: 'bg-neutral-200'
            })[variant],
        [variant]
    );

    const isInActiveState = useMemo(() => isFocused || !!value, [isFocused, value]);

    const labelClassName = useClassNames([
        { ['font-label']: labelPosition === 'top' },
        { [isInActiveState && label ? 'opacity-1 scale-100' : 'scale-0 opacity-0']: labelPosition === 'inline' },
        {
            ['absolute left-[12px] top-[6px] block font-label transition duration-150 ease-out']: labelPosition === 'inline'
        }
    ]);

    const labelContainerClassName = useClassNames([
        {
            'mb-8': labelPosition === 'top'
        },
        customClass
    ]);

    const inputClassName = useClassNames([
        'h-45 w-full rounded-md border border-input-border px-12 text-primary-black placeholder:text-14 placeholder:leading-normal placeholder:text-secondary-black focus:border-input-border focus:outline-none focus:ring-0 disabled:cursor-not-allowed disabled:text-input-disabled',
        bgClassName,
        isInActiveState && label && labelPosition == 'inline' ? 'pb-[4px] pt-[20px]' : 'py-10',
        {
            'border-input-error focus:border-input-border':
                (!!props.error && isInActiveState) ||
                isErrored ||
                !!errorMessage ||
                (typeof props.isValid !== 'undefined' && !props.isValid && isTouched)
        },
        { 'border-green-500 focus:border-green-500': (!!props.isValid && isInActiveState) || isValid },
        className
    ]);

    const propsForInput = structuredClone(props);
    delete propsForInput.isValid;

    return (
        <div className="relative" data-error={!!errorMessage || isErrored || (!!props.error && isInActiveState)}>
            {label && (
                <div className={labelContainerClassName}>
                    <label htmlFor={props.name} className={`${labelClassName} text-md leading-loose`}>
                        {props.required ? `${label} *` : label}
                    </label>
                </div>
            )}
            {labelDesc && (
                <div className={labelContainerClassName}>
                    <Typography as="label" className={labelClassName}>
                        {` (${labelDesc})`}
                    </Typography>
                </div>
            )}
            <div className="relative">
                <input
                    className={inputClassName}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onPaste={handlePaste}
                    ref={innerRef}
                    value={value}
                    id={props.name}
                    {...propsForInput}
                />
                {(isValid || (props.isValid && !isInActiveState)) && !isFocused && !hideCheckIcon && (
                    <CheckIcon className="absolute right-12 top-[50%] size-16 translate-y-[-50%] text-green-500" />
                )}
                {children}
            </div>
            {((isErrored || props.error) ?? errorMessage) && (
                <Typography
                    className="mt-12 text-sm leading-tight text-input-error"
                    as="p"
                    data-cy={props['data-cy'] ? `error-${props['data-cy']}` : ''}
                >
                    {props.error ?? errorMessage}
                </Typography>
            )}
        </div>
    );
};

export default Input;
