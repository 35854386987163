import { states } from '@wilm/shared-types/validation-rules/states';
import { FieldType } from '@wilm/shared-types/validation-rules/types';
import type { EnumFieldDefinition, Fields, StringFieldDefinition } from '@wilm/shared-types/validation-rules/types';
import { FieldValidationRules } from '@wilm/shared-types/validation-rules/common';

export interface CustomerAddressFields extends Fields {
    firstName: StringFieldDefinition;
    lastName: StringFieldDefinition;
    streetName: StringFieldDefinition;
    additionalAddressInfo: StringFieldDefinition;
    city: StringFieldDefinition;
    country: EnumFieldDefinition;
    region: EnumFieldDefinition;
    postalCode: StringFieldDefinition;
    phone: StringFieldDefinition;
    email: StringFieldDefinition;
}

export const fields: CustomerAddressFields = {
    firstName: {
        name: 'firstName',
        type: FieldType.STRING,
        value: '',
        validation: {
            required: true,
            regex: FieldValidationRules.NAME.REGEX,
            minLength: FieldValidationRules.NAME.MIN,
            maxLength: FieldValidationRules.NAME.MAX,
            errorMessages: {
                validation: 'error.missing.firstName',
                range: 'error.range.firstName',
                regex: 'error.validation.firstName'
            }
        }
    },
    lastName: {
        name: 'lastName',
        type: FieldType.STRING,
        value: '',
        validation: {
            required: true,
            regex: FieldValidationRules.NAME.REGEX,
            minLength: FieldValidationRules.NAME.MIN,
            maxLength: FieldValidationRules.NAME.MAX,
            errorMessages: {
                validation: 'error.missing.lastName',
                range: 'error.range.lastName',
                regex: 'error.validation.lastName'
            }
        }
    },
    email: {
        name: 'email',
        type: FieldType.STRING,
        value: '',
        validation: {
            required: true,
            regex: FieldValidationRules.EMAIL.REGEX,
            minLength: FieldValidationRules.EMAIL.MIN,
            maxLength: FieldValidationRules.EMAIL.MAX,
            errorMessages: {
                validation: 'error.missing.email',
                range: 'error.range.email',
                regex: 'error.validation.email'
            }
        }
    },
    streetName: {
        name: 'streetName',
        type: FieldType.STRING,
        value: '',
        validation: {
            required: true,
            minLength: FieldValidationRules.ADDRESS.MIN,
            maxLength: FieldValidationRules.ADDRESS.MAX,
            regex: FieldValidationRules.ADDRESS.REGEX,
            errorMessages: {
                validation: 'error.missing.streetName',
                range: 'error.range.streetName',
                regex: 'error.regex.streetName'
            }
        }
    },
    additionalAddressInfo: {
        name: 'additionalAddressInfo',
        type: FieldType.STRING,
        value: '',
        validation: {
            required: false,
            minLength: FieldValidationRules.ADDRESS.MIN,
            maxLength: FieldValidationRules.ADDRESS.MAX,
            regex: FieldValidationRules.ADDRESS.REGEX,
            errorMessages: {
                range: 'error.range.additionalAddressInfo',
                regex: 'error.regex.additionalAddressInfo'
            }
        }
    },
    city: {
        name: 'city',
        type: FieldType.STRING,
        value: '',
        validation: {
            required: true,
            regex: FieldValidationRules.CITY.REGEX,
            minLength: FieldValidationRules.CITY.MIN,
            maxLength: FieldValidationRules.CITY.MAX,
            errorMessages: {
                validation: 'error.missing.city',
                range: 'error.range.city',
                regex: 'error.validation.city'
            }
        }
    },
    country: {
        name: 'country',
        type: FieldType.ENUM,
        value: '',
        options: [
            { label: 'United States', value: 'US' },
            { label: 'Canada', value: 'CA' }
        ],
        getOptions: (fields: Fields) => {
            return (fields.country as EnumFieldDefinition).options;
        },
        validation: {
            required: true,
            errorMessages: {
                validation: 'error.missing.country'
            }
        }
    },
    region: {
        name: 'region',
        type: FieldType.ENUM,
        value: '',
        options: [],
        getOptions: (fields: Fields) => {
            const country = (fields.country as EnumFieldDefinition).value;
            const placeholder = { value: '', label: 'Please select your state/province' };
            return country in states ? [placeholder, ...states[country as keyof typeof states]] : [placeholder];
        },
        showOnPredicate: (fields: Fields) => {
            return (fields.country as EnumFieldDefinition).value in states;
        },
        validation: {
            errorMessages: {
                validation: 'error.missing.region'
            },
            requiredPredicate: (fields: Fields) => {
                const countriesThatRequireState = ['US', 'CA'];
                return countriesThatRequireState.includes((fields.country as EnumFieldDefinition).value ?? '');
            }
        }
    },
    postalCode: {
        name: 'postalCode',
        type: FieldType.STRING,
        value: '',
        validation: {
            required: true,
            regex: FieldValidationRules.POSTAL.REGEX,
            minLength: FieldValidationRules.POSTAL.MIN,
            maxLength: FieldValidationRules.POSTAL.MAX,
            errorMessages: {
                validation: 'error.missing.postalCode',
                range: 'error.range.postalCode',
                regex: 'error.validation.postalCode'
            }
        }
    },
    phone: {
        name: 'phone',
        type: FieldType.STRING,
        value: '',
        validation: {
            required: true,
            regex: FieldValidationRules.PHONE.REGEX,
            minLength: FieldValidationRules.PHONE.MIN,
            maxLength: FieldValidationRules.PHONE.MAX,
            errorMessages: {
                validation: 'error.missing.phone',
                range: 'error.missing.phone'
            }
        }
    }
};
